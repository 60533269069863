import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import { MAIL_TO_SUPPORT_URL, APP_URL} from '../../../../settings';

function Hero({ scheduleDemo, getStarted }) {

    const returnDetailDesc = () => {
        return 'Personal finance management, made easy.'
    }

    const quickstart = () => {
        window.location.href ='./quickstart'
    }

    const sample = () => {
        window.location.href=MAIL_TO_SUPPORT_URL
    }

    const docs = () => {
        window.location.href='./documentation'
    }

    const returnLeftSection = () => {
        return (
            <Container className={styles.section}>
                {/* <Text className={styles.summaryText} text={'NO CREDIT NEEDED'} /> */}
                <Text className={styles.heroText} text={'Stay on track of expenses before payday.'} />
                <Container className={styles.swathImage}>
                </Container>
                <Text className={styles.detailText} text={returnDetailDesc()} />
                <Container className={styles.buttonsContainer}>
                    <Button text={'Try For Free'} onItemPress={getStarted} primary={true} className={styles.btn} />
                    <Button text={'Contact Support'} onItemPress={sample} className={styles.btn} />
                </Container>
            </Container>
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={styles.firstImage}>
                </Container>
                <Container className={styles.secondImage}>
                </Container>
                <Container className={styles.thirdImage}>
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}
            </Container>

        </Container>
    );
}

export default Hero;
