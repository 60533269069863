import styles from './styles';
import React, { useState, useEffect } from 'react'
import Nav from '../../components/Nav';
import Text from '../../components/Text';
import Loading from '../../components/Loading';
import Container from '../../components/Container';
import API from '../../API/API.js'
import Form from '../../components/Form';

function Logs({ }) {

    const [sub, setSub] = useState(null)
    const [events, setEvents] = useState(null)

    useEffect(() => {
        // getLogs()
    }, []);

    const getLogs = async () => {
        let subItem = JSON.parse(await localStorage.getItem('logs_subscription'))
        console.log('subitem: ', JSON.stringify(subItem))
        setSub(subItem)
        if (!!subItem) {
            let result = await API.getEvents({ UUID: subItem.UUID });
            console.log('API result: ', result);
            if (result && result.data && result.data.data && result.data.data.events) {
                console.log('result.data.data.events: ', JSON.stringify(result.data.data.events))
                setEvents(result.data.data.events)

                // setEvents([{event_properties: {event_values: {test: '111101101101101101101101101101101101101101101101101101101101101101101101101101101101101101101101100110110110110110110110110110000000011100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000', test2: 'jsdf'}}, event_type: 'test', time_ISO: 'testing123 date here' }])
            }
        }
    }

    // const btnPressed = (payload) => {
    //     console.log('payload: ', JSON.stringify(payload))
    //     if (type==='login') {
    //         login(payload)
    //     }
    //     else if (type==='forgotPassword') {
    //         resetPassword(payload)
    //     }
    //     else if (type==='register') {
    //         register(payload)
    //     }
    // }

    // const register = async (payload) => {
    //     if (!payload) {
    //         showError('Please provide an email.')
    //     }
    //     else if (!payload.email) {
    //         showError('Missing email.')
    //     }
    //     else if (!validateEmail(payload.email)) {
    //         showError('Invalid email.')
    //     }
    //     else{
    //         showError(null)
    //         console.log('register pressed')
    //         let response = await API.register({email: payload.email.trim()})
    //         if (response.data.token)    {
    //             let token = response.data.token
    //             await localStorage.setItem('jwt', token);
    //             window.location.href = '/connect'
    //         }
    //         // alert('Coming soon! Please email info@blocktimize.io for beta access.')

    //     }
    // }

    // const login = async (payload) => {
    //     console.log('login has been pressed')
    //     if (!payload) {
    //         showError('Please provide your email and password.')
    //     }
    //     else if (!payload.email) {
    //         showError('Missing email.')
    //     }
    //     else if (!validateEmail(payload.email)) {
    //         showError('Invalid email.')
    //     }
    //     else if (!payload.password) {
    //         showError('Missing password.')
    //     }
    //     else if (payload.password.length<6) {
    //         showError('Passwords must be at least 6 characters.')
    //     }
    //     else {
    //         showError(null)
    //         console.log('logging in...')
    //         let response = await API.login({email: payload.email.trim(), password: payload.password})
    //         console.log('response: ', response)
    //         if (response.data.token)    {
    //             let token = response.data.token
    //             await localStorage.setItem('jwt', token);
    //             window.location.href = '/connect'
    //         }
    //         // aler
    //     }

    // }

    const returnShortenedVal = (val) => {
        if (val.length>45)  {
            return val.substring(0,45) + '...'
        }
        return val
    }

    const renderEventValues = (event) => {
        if (!event.event_properties) return null
        if (!event.event_properties.event_values) return null
        let obj = event.event_properties.event_values
        let values = Object.keys(event.event_properties.event_values)
        return (
            <React.Fragment>
                {values.map((val, index) => (
                    <Container className={styles.valuesContainer}>
                        <Text className={styles.key} text={val} />
                        <p className={styles.val} >{obj[val]}</p>
                    </Container>
                ))}
            </React.Fragment>
        )
    }

    if (!events)    {
        return (
            <Loading message='Loading Events...' />
        )
    }

    return (
        <Container className={styles.background}>
            {/* <Text className={styles.title} text={'Event Logs'} /> */}
            {!!sub && !!sub.name && (
                <Text className={styles.title} text={sub.name + ' Events'} />
            )}
            {events && events.length === 0 && (
                <Container className={styles.eventContainer}>
                    <Text className={styles.eventTitle} text={'No Events Yet'} />
                    <Text className={styles.date} text={'Events will show up here.'} />

                </Container>

            )}
            {!!events && (
                <React.Fragment>
                    {events.map((eventItem, index) => (
                        <Container className={styles.eventContainer}>
                            <Text className={styles.eventTitle} text={eventItem.event_type} />
                            {renderEventValues(eventItem)}
                            <Text className={styles.date} text={eventItem.time_ISO} />
                        </Container>
                    ))}
                </React.Fragment>
            )}
            {/* <Nav includeSubTitle={true}/>
            <Container className={styles.formContainer}>
                <Container className={styles.form}>
                    <Form type={type} btnPressed={btnPressed} errorMsg={errorMsg}/>
                </Container>
            </Container> */}

        </Container>
    );
}

export default Logs
