import React from 'react'
import styles from './styles'

const Loading = ({message={message}}) => {

  return (
    <div className={styles.mainContainer}>
      <p className={styles.message}>{message}</p>
    </div>
  )
}

export default Loading


