import styles from './styles';
import React, { useState, useEffect } from 'react'
import Nav from '../../components/Nav';
import Container from '../../components/Container';
import Hero from './components/Hero';
import { APP_URL, MAIL_TO_SUPPORT_URL} from '../../settings';
import Button from '../../components/Button'
import Comparison from './components/Comparison';
import { isMobile } from "react-device-detect";
import Features from './components/Features';
import Steps from './components/Steps';
import Focus from './components/Focus';
import Footer from './components/Footer';

function Landing({ }) {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        checkAuthed()
    }, []);

    const support = () => {
        window.location.href=MAIL_TO_SUPPORT_URL
    }

    const launch = () => {
        // let landing = 'mailto:' + SUPPORT_EMAIL + '?subject=Launch A Drop&body=Send this email to join NFTDropper and launch your first drop.'
        // console.log(landing)
        window.open(APP_URL)    }

    const checkAuthed = async () => {
        let token = await localStorage.getItem('jwt')
        console.log('token in home: ', token)
        if (!!token) {
            window.location.href = './connect'
        }
        setLoading(false)
    }

    const scheduleDemo = () => {
        // let landing = 'mailto:' + SUPPORT_EMAIL + '?subject=Schedule A Demo&body=Send this email to schedule a demo of NFTDropper.'
        // console.log(landing)
        window.open(APP_URL)
    }

    const getStarted = () => {
        window.location.href = APP_URL
    }

    const login = () => {
        window.location.href = '/login'
    }

    const goToDocs = () => {

    }

    const returnDetailDesc = () => {
        return 'Get a portion of your next paycheck every day. Download for iOS and get started.'
    }

    if (loading) {
        return null
    }

    if (isMobile) {
        return (
            <React.Fragment>
                <Container className={styles.mobileHeader}>
                    Stream
                </Container>
                {/* <Container className={styles.mobileBackground3}>Launch NFT drops Effortlessly!
                </Container> */}

<Container className={styles.firstImage}>
                </Container>


                <Container className={styles.header}>Your money, before payday.
                </Container>
                <Container className={styles.mobileBackground}>{returnDetailDesc()}
                </Container>




           


                <Button primary={true} onItemPress={launch} className={styles.mobileBtn} text={"Try For Free"}></Button>
                <Button onItemPress={support} className={styles.mobileBtn} text={"Contact Support"}></Button>




{/* 
                <Container className={styles.mobileBottomContainer}>
                <Container className={styles.mobileBackground2}>Use NFTDropper to collect emails ahead of launch and automatically remind people on launch day.
                </Container>

                <Container className={styles.mobileBackground2}>Sell multiple copies of NFTs like tickets, game items, and other limited edition collectibles.
                </Container>

                <Container className={styles.mobileBackground2}>Focus on designing your collection and we'll handle the hard parts. No code needed.
                </Container> 

                </Container>*/}

            </React.Fragment>
        )
    }

    return (
        <Container className={styles.background}>

            <Nav scheduleDemo={scheduleDemo} getStarted={getStarted} login={login} showButtons={true} />
            <Hero scheduleDemo={scheduleDemo} getStarted={getStarted} />
            <Steps scheduleDemo={scheduleDemo} getStarted={getStarted} />
            {/* <Focus scheduleDemo={scheduleDemo} getStarted={getStarted} />
            <Comparison scheduleDemo={scheduleDemo} getStarted={getStarted} />
            <Features scheduleDemo={scheduleDemo} getStarted={getStarted} /> */}
            <Footer scheduleDemo={scheduleDemo} getStarted={getStarted} />



        </Container>
    );
}

export default Landing;
