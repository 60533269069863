import baseAPI from './baseAPI'
import { SERVER_BASE } from '../settings.js';

const { get, post } = baseAPI

const API = {
  async getSale(payload) {
    const endpoint = SERVER_BASE + '/fetch_sale'
    const result = await get(endpoint,payload)
    return result
  },
  async getContract(payload) {
    const endpoint = SERVER_BASE + '/fetch_contract'
    const result = await get(endpoint,payload)
    return result
  },

  async createFundingAccount(payload) {
    const endpoint = SERVER_BASE + '/create_funding_account'
    const result = await post(endpoint, payload)
    return result
  },



  async getEvents(payload) {
    const endpoint = SERVER_BASE + '/events'
    const result = await get(endpoint,payload)
    return result
  },
  async register(payload) {
    const endpoint = SERVER_BASE + '/register'
    const result = await post(endpoint, payload)
    return result
  },
  async login(payload) {
    const endpoint = SERVER_BASE + '/login'
    const result = await post(endpoint, payload)
    return result
  },
  async subscribe(payload) {
    console.log('sub data: ', payload)
    const endpoint = SERVER_BASE + '/subscribe'
    const result = await post(endpoint, payload)
    return result
  },
  async getSubscriptions() {
    const endpoint = SERVER_BASE + '/subscriptions'
    const result = await get(endpoint)
    return result
  },
  async deleteSubscription(payload) {
    const endpoint = SERVER_BASE + '/delete_subscription'
    const result = await post(endpoint,payload)
    return result
  },
  async resetPassword(payload) {
    const endpoint = SERVER_BASE + '/user/reset/accept'
    const result = await post(endpoint,payload)
    return result
  },
  // async updateSubscription(payload) {
  //   const endpoint = SERVER_BASE + '/update_subscription'
  //   const result = await post(endpoint,payload)
  //   return result
  //  },
}

export default API
