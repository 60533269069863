import styles from './AppStyles';
import Button from './components/Button';
import Container from './components/Container';
import Landing from './Screens/Landing';
import {colors,fonts} from './Styles'

function App() {

  // document.body.style = 'background-color: '+colors.bodyBackground;

  function startTrading() {
    window.location.href = '/dashboard?ref=home'
  }

  function showTutorial() {
    window.open('/terms','_blank')
  }

  return (
    <Landing />
  );
}

export default App;
