
import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'
import Button from '../../components/Button';
import API from '../../API/API.js'


function ResetPassword({ }) {

    const [newPassword, setNewPassword] = useState(null)
    const [confirmedPassword, setConfirmedPassword] = useState(null)
    const [code, setCode] = useState(null)

    // const sp = new URLSearchParams(window.location.search);
    // const CODE = sp.get('code') ? sp.get('code') : null
    // console.log("Code: ", CODE)
    // 
    useEffect(() => {
        const sp = new URLSearchParams(window.location.search);
        setCode(sp.get('code'))
    }, []);

    const submit = async () => {
        if (!code) {
            alert("Invalid reset link.")
            return
        }
        if (!newPassword) {
            alert("New password required.")
            return
        }
        if (!(newPassword === confirmedPassword)) {
            alert("Confirmed password does not match.")
            return
        }
        if (newPassword.length < 8) {
            alert("New password must be 8 characters.")
            return
        }
        if (!testPassword(newPassword)) {
            alert("Your password is not strong enough. Please make sure you have at least one upper case letter, a lower case letter, a digit, and a special character.")
            return
        }
        console.log("Submit button pressed.")
        let payload = { newPassword, code }
        try {
            let result = await API.resetPassword(payload);
            if (result && result.data && (result.data.status === "success")) {
                alert("Your password has been reset.")
            } else {
                alert("Password reset failed. Please try again.")
            }
        } catch (error) {
            alert("Password link may be invalid or expired. Please generate a new link.")
        }
    }

    function testPassword(pwString) {
        var strength = 0;

        strength += /[A-Z]+/.test(pwString) ? 1 : 0;
        strength += /[a-z]+/.test(pwString) ? 1 : 0;
        strength += /[0-9]+/.test(pwString) ? 1 : 0;
        strength += /[\W]+/.test(pwString) ? 1 : 0;

        switch (strength) {
            case 3:
                // its's medium!
                return false
            case 4:
                // it's strong!
                return true
            default:
                // it's weak!
                return false
        }
    }

    const handleNewPassChange = (event) => {
        let newVal = event.target.value
        setNewPassword(newVal)
    }

    const handleConfirmPassChange = (event) => {
        let newVal = event.target.value
        setConfirmedPassword(newVal)
    }

    const returnForm = () => {
        return (
            <React.Fragment>

                <Text className={styles.headerText} text={'Password Reset'} />

                <Container className={styles.form}>

                    <TextField
                        placeholder="New password"
                        // id={index.toString()}
                        // label={field.name}
                        // defaultValue={this.props.defaultToDoValue}
                        type="password"
                        className={styles.textInput}
                        // multiline
                        // margin="normal"
                        // type={false}
                        variant="outlined"
                        onChange={(e) => handleNewPassChange(e)}
                    />

                    <p> </p>


                    <TextField
                        placeholder="Confirm password"
                        // id={index.toString()}
                        // label={field.name}
                        // defaultValue={this.props.defaultToDoValue}
                        type="password"
                        className={styles.textInput}
                        // multiline
                        // margin="normal"
                        // type={false}
                        variant="outlined"
                        onChange={(e) => handleConfirmPassChange(e)}
                    />

                    <Button text={"Reset"} onItemPress={() => submit()} primary={true} className={styles.btn} />
                </ Container >
            </React.Fragment>
        )
    }

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default ResetPassword
