import React, { useEffect } from 'react';
// import { usePlaidLink } from 'react-plaid-link';
const OAuthLink = () => {
  // The Link token from the first Link initialization
  // const linkToken = 'foo' // localStorage.getItem('link_token');
  // const onSuccess = React.useCallback((public_token) => {
  //   // send public_token to server, retrieve access_token and item_id
  //   // return to "https://example.com" upon completion
  // });
  // const onExit = (err, metadata) => {
  //   // handle error...
  // };
  // const config = {
  //   token: linkToken,
  //   receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
  //   onSuccess,
  //   onExit,
  // };
  // const { open, ready, error } = usePlaidLink(config);
  // // automatically reinitialize Link
  // useEffect(() => {
  //   if (ready) {
  //     open();
  //   }
  // }, [ready, open]);
  // return <></>;

  return null
};

export default OAuthLink;