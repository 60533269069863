import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../components/Container';
import TextField from '@material-ui/core/TextField';
import Button from '../../components/Button';
import Text from '../../components/Text';

function Form({ btnPressed, type, errorMsg }) {

    const [values,setValues]=useState({})

    const DEFAULTS = {
        login: {
            headerText: 'Log In',
            btnText: 'Log In',
            subText: 'Forgot password?',
            subTextDest: '/password-reset',
            footerText: 'Don\'t have an account?',
            footerTextClickable: 'Sign Up',
            footerClickableDest: '/register',
            textFields:
                [{
                    name: 'Email',
                    placeholder: '',
                    isPassword: false,
                },
                {
                    name: 'Password',
                    placeholder: '',
                    isPassword: true,
                }]
        },
        register: {
            headerText: 'Get Started',
            btnText: 'Join Waitlist',
            subText: 'Accepting waitlist signups.',
            footerText: 'Already have an account?',
            footerTextClickable: 'Sign In',
            footerClickableDest: '/login',
            textFields:
                [{
                    name: 'Email',
                    placeholder: '',
                    isPassword: false,
                }]
        },
        forgotPassword: {
            headerText: 'Forgot Password',
            btnText: 'Email Me',
            subText: null,
            footerText: 'Don\'t have an account?',
            footerTextClickable: 'Sign Up',
            footerClickableDest: '/register',
            textFields:
                [{
                    name: 'Email',
                    placeholder: '',
                    isPassword: false,
                }]
        },
    }

    const returnForm = () => {
        return type
    }

    const openLink = link => {
        window.location.href = link
    }

    const isPasswordField = (index) => {
        return DEFAULTS[returnForm()].textFields[index].isPassword
    }

    const openSubTextDest = () => {
        if (!DEFAULTS[returnForm()].subTextDest) return
        window.location.href=DEFAULTS[returnForm()].subTextDest
    }

    const handleChange = (event, index) => {
        let temp = values
        temp[returnName(index).toLowerCase()]=event.target.value
        setValues(temp)
    }

    const returnName = (index) => {
        return DEFAULTS[returnForm()].textFields[index].name
    }

    const generateForm = () => {
        return (
            <React.Fragment>
                <Text className={styles.headerText} text={DEFAULTS[returnForm()].headerText} />
                {DEFAULTS[returnForm()].textFields.map((field, index) => (
                    <React.Fragment key={index}>
                        <Text className={styles.fieldText} text={returnName(index)} />
                        <TextField
                            id={index.toString()}
                            // label={field.name}
                            // defaultValue={this.props.defaultToDoValue}
                            className={styles.textInput}
                            // multiline
                            // margin="normal"
                            onKeyPress={(ev) => {
                                console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                  btnPressed(values)
                                  ev.preventDefault();
                                }
                              }}
                            type={isPasswordField(index) ? 'password' : null}
                            variant="outlined"
                            onChange={ (e) => handleChange(e,index)}
                        />
                    </React.Fragment>
                ))}
                {DEFAULTS[returnForm()].subText && !errorMsg && (
                    <Container onClick={openSubTextDest}>
                    <Text className={styles.subText} text={DEFAULTS[returnForm()].subText} />
                    </Container>
                )}
                { errorMsg && (
         
                    <Text className={styles.errorSubText} text={errorMsg} />
       
                )}
                <Button text={DEFAULTS[returnForm()].btnText} onItemPress={() => btnPressed(values)} primary={true} className={styles.btn} />
                <Container className={styles.footer}>
                    <Text className={styles.footerText} text={DEFAULTS[returnForm()].footerText} />
                    <Container onClick={() => openLink(DEFAULTS[returnForm()].footerClickableDest)}>
                        <Text className={styles.footerTextClickable} text={DEFAULTS[returnForm()].footerTextClickable} />
                    </Container>
                </Container>
            </React.Fragment>
        )
    }

    return (
        <Container className={styles.background}>
            {generateForm()}
        </Container>
    );
}

export default Form;
