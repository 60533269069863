import {fonts,colors} from '../../Styles'
import { css } from 'emotion'
import BPY from './components/Hero/Images/bapeyacht.webp'
import BMR from './components/Hero/Images/hero.png'


const mobileBackground = css`
    height: 100%;
    // width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    // font-weight: 600;
    font-size: 16px;
    // color: white;
    // background-color: #2991F0;
    // display: flex;
    // align-items: center;
    font-family: ${fonts.primary};
    text-align: center;

`

const mobileBackground3 = css`
    height: 100%;
    // width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    // font-weight: 700;
    font-size: 14px;
    // color: white;
    // background-color: #2991F0;
    // font-size: 22px;
    // display: flex;
    // align-items: center;
    font-family: ${fonts.primary};
    text-align: center;
    content-align: center;
    font-weight: light;
    text-align: center;
s
`

const mobileBackground2 = css`
    height: 100%;
    // width: 100%;
    padding: 20px;
    // font-weight: 600;
    font-size: 14px;
    // color: ${colors.primary};
    color: white;
    // background-color: black;
    font-size: 15px;
    // display: flex;
    // align-items: center;
    font-family: ${fonts.primary};
    text-align: center;
    border: 1px solid gray;
    width: 250px;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 40px;

`

const mobileHeader= css`
height: 100%;
// width: 100%;
padding: 10%;
font-weight: 600;
// color: white;
// background-color: #2991F0;
font-size: 18px;
// display: flex;
// align-items: center;
// font-size: 14px;
font-family: ${fonts.primary};

`

const mobileBtn= css`
margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 14px;
    font-family: ${fonts.primary};
`

const header = css`
margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 35px;
    font-family: ${fonts.primary};
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
`


const firstImage = css`
  width: 173px;
  height: 153px;
  border-radius: 24px;
  background-image: url(${BMR});
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const mobileBottomContainer =  css`
    background-color: black;
    padding: 40px;
    margin-top: 40px;
`

const styles = {
    mobileBackground,
    mobileBtn,
    firstImage,
    mobileBackground3,
    mobileBottomContainer,
    mobileHeader,
    mobileBackground2,
    header,
}

export default styles




