// import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  // background-color: #EAF4FE;
  // height: 1000px;
`

const formContainer = css`
  display: flex;
  justify-content: center;
`


const form = css`
    width: 426px;
    margin-top: 80px;
    background-color: white;
    padding: 45px;
    border-radius: 12px;
    border: 1px solid lightgray;
    margin-bottom: 100px;
`

const headerText = css`
  width: 100%;
  text-align: center;
  font-size: 30px;
`

const explanationText = css`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
  color: rgba(17, 17, 17, 0.8);
  font-size: 15px;
  line-height: 18px;
  text-align: center;
`

const directionsText  = css`
  width: 100%;
  // text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: black;
`

const subContainer = css`
  // background-color: #EAF4FE;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  margin-top: 20px;
`

const subFieldContainer = css`
  padding: 4px;
  border-radius: 5px;
  background-color: lightgray;
`

const subsOuterContainer = css`
  width: 100%;
  margin-bottom: 40px;
  // background-color: red;

`

const subNumProcessedText = css`
  width: 100%;
  margin-top:10px;
  color: grey;
  margin-bottom: 20px;

`

const btn = css`

margin-top:10px;


`

const delBtn = css`
  // width: 100px;
  // height: 20px;
  margin-top:10px;
  color: #ff8c69;
  background-color: white;
  border: 1px solid #ff8c69;
  &:hover {
      background-color: #ff8c69;
      border: 1px solid #ff8c69;
      color: white;
    }


`

const subNameText = css`
  font-size: 25px;
    font-weight: 600;

`

const subAddressText = css`
margin-top: 10px;
  margin-bottom: 5px;
  color: rgba(17, 17, 17, 0.8);
`

const subAnalyticsText = css`
  margin-bottom: 5px;
  color: rgba(17, 17, 17, 0.8);
`

const subWebhookText = css`
  margin-bottom: 5px;
  color: rgba(17, 17, 17, 0.8);
`

const feedbackText  = css`
width: 100%;
text-align: center;
margin-top: 16px;
margin-bottom: 24px;
color: gray;
font-size: 15px;
line-height: 18px;
text-align: center;
`

const styles = {
  background,
  delBtn ,
  subFieldContainer,
  subAnalyticsText,
  subAddressText,
  subWebhookText,
  btn,
  form,
  subContainer,
  formContainer,
  headerText,
  feedbackText,
  subNameText,
  subNumProcessedText,
  explanationText,
  subsOuterContainer,
  directionsText,

}

export default styles




