import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import Button from '../../components/Button';
import Nav from '../../components/Nav';
import AddContract from './components/AddContract'
import styles from './styles'
import API from '../../API/API.js'
import {  SUPPORT_EMAIL } from '../../settings'


function Connect({ }) {

    const [subs, setSubs] = useState([])
    const [connecting, setConnecting] = useState(false)

    useEffect(() => {
        getToken()
        getSubs()
    }, [connecting]);

    const newConnection = () => {
        alert('Thanks for your interest in creating a drop. We will be in touch within 24 hours to move you off the waitlist.')
        // setConnecting(true)
    }

    const editConnection = () => {
        setConnecting(true)
        // TODO write
    }

    const getToken = async () => {
        let token = await localStorage.getItem('jwt')
        if (!token) {
            window.location.href = './'
        }
    }

    const getSubs = async () => {
        console.log('Getting subs...')
        let res = await API.getSubscriptions()
        console.log('subscriptions of user: ', JSON.stringify(res.data.data))
        setSubs(res.data.data.subscriptions)
    }

    const returnExplanation = () => {
        let base = ''
        base += 'Create your NFT drop in just a few easy steps below.'
        // if (CHAIN_NAME.toUpperCase() === 'MAINNET') {
        //     if (subs.length===0)    {
        //         base += 'Add Ethereum mainnet contracts below.'
        //         base += ' Switch to Ropsten testnet by creating an account on testblocktimize.io'
        //     } else {
        //         base += 'Connected contracts are shown below.'
        //         base += ' Switch to Ropsten testnet by creating an account on testblocktimize.io'
        //     }
        // } else {
        //     if (subs.length===0)    {
        //         base += 'Create your NFT drop in just a few easy steps below.'
        //     } else {
        //         base += 'Connected Ropsten contracts are shown below.'
        //         base += ' Switch to production mode by creating an account on blocktimize.io'
        //     }
        // }
        return base
    }

    const returnButtonCopy = () => {
        return 'Create Drop'
    }


    const viewAnalytics = async (sub) => {
        await localStorage.setItem('analytics_subscription', JSON.stringify(sub))
        window.location.href='/analytics'
    }

    const viewLogs = async (sub) => {
        await localStorage.setItem('logs_subscription', JSON.stringify(sub))
        window.location.href='/logs'
    }

    const renderSubs = () => {
        if (!subs) return null
        return (
            <Container className={styles.subsOuterContainer} >
                {subs.map((sub, index) => (
                    <Container className={styles.subContainer} key={index} >
                        <Text className={styles.subNameText} text={sub.name} />

                        {/* <Container className={styles.subFieldContainer}> */}
                        <Text className={styles.subAddressText} text={sub.subscribe_to} />
                        {/* </Container> */}
                        <Text className={styles.subAnalyticsText} text={'Amplitude: ' + (sub.redacted_destination_api_key ? 'Connected' : 'Not Connected')} />
                        <Text className={styles.subWebhookText} text={'Event Webhooks: ' + (!!sub.webhook_url ? sub.webhook_url : 'Not Enabled')} />
                        <Text className={styles.subWebhookText} text={'Status: Active'} />

                        <Text className={styles.subNumProcessedText} text={sub.num_events_processed + ' events processed.'} />
                        <Button text={'View Analytics'} onItemPress={() => viewAnalytics(sub)} className={styles.btn} />
                        <Button text={'View Events'} onItemPress={() => viewLogs(sub)} className={styles.btn} />
                        <Button text={'Disconnect'} onItemPress={() => deleteSub(sub.UUID)} className={styles.delBtn} />

                    </Container>
                ))}
            </Container>
        )
    }

    const deleteSub = async (UUID) => {
        let res = await API.deleteSubscription({UUID})
        getSubs()
    }

    // const returnDirections = () => {
    //     return 'In less than 5 minutes, you\'ll be receiving analytics, event webhooks, and more!'
    // }

    const returnForm = () => {
        return (
            <React.Fragment>
                {subs.length === 0 ? (
                    <React.Fragment>
                        <Text className={styles.headerText} text='Create Drop' />
                        <Text className={styles.explanationText} text={returnExplanation()} />
                    </React.Fragment>
                ) : (
                <React.Fragment>
                    <Text className={styles.headerText} text='Connected Contracts' />
                    <Text className={styles.explanationText} text={returnExplanation()} />
                </React.Fragment>
                )}
                {renderSubs()}
                <Button text={returnButtonCopy()} onItemPress={newConnection} primary={true} className={styles.btn} />
                <Text className={styles.feedbackText} text={'Need help or have questions? Email ' + SUPPORT_EMAIL} />

            </ React.Fragment>
        )
    }

    const closeNew = () => {
        getSubs()
        setConnecting(false)
    }

    return (
        <Container className={styles.background}>
            <Nav includeSubTitle={true}/>
            <Container className={styles.formContainer}>
                <Container className={styles.form}>
                    {connecting ? (
                        <AddContract close={closeNew} />
                    ) : (
                        returnForm()
                    )}
                </Container>
            </Container>
        </Container>
    );
}

export default Connect
