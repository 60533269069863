import {colors,fonts} from '../../Styles'
import { css } from 'emotion'

const styles = {

}

export default styles



