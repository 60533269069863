// import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  background-color: white;
  padding: 48px;
  border-radius: 12px;
`

const textInput = css`
    width: 100%;

`

const fieldText = css`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`

const headerText = css`
    height: 25px;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #111111;
    margin-bottom: 32px;

`

const btn = css`
    margin-top: 20px;
    height: 48px;
    border-radius: 100px;

`

const subText = css`
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.15px;
    color: #2A91F0;
    margin-top: 8px;
    cursor: pointer;

`

const errorSubText = css`
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.15px;
    color: red;
    margin-top: 8px;
    cursor: pointer;

`


const footerText = css`
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: 0.15px;
`

const footerTextClickable = css`
    color: #2A91F0;
    cursor: pointer;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: 0.15px;
    margin-left: 8px;
`

const footer = css`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
`

const styles = {
  background,
  headerText,
  fieldText,
  btn,
  footer,
  errorSubText,
  subText,
  footerText,
  footerTextClickable,
  textInput,
}

export default styles




