// ads via Carbon
// The building blocks to create a fast, relevant search experience
// ADS VIA CARBON
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import Button from '../../components/Button';
import Nav from '../../components/Nav';
import Container from '../../components/Container';
import Loading from '../../components/Loading';
import styles from './styles'
import API from '../../API/API.js'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
function Analytics({ }) {

    const [sub, setSub] = useState(null)
    const [finalSeries, setFinalSeries] = useState(null)
    const [options, setOptions] = useState(null)
    const [startTime, setStartTime] = useState(null)

    useEffect(() => {
        // getLogs()
    }, []);

    const getLogs = async () => {
        let subItem = JSON.parse(await localStorage.getItem('analytics_subscription'))
        console.log('subitem: ', JSON.stringify(subItem))
        setSub(subItem)
        if (!!subItem) {
            console.log('sub item: ', subItem)
            setStartTime(subItem.created_at_ISO)
            let result = await API.getEvents({ UUID: subItem.UUID });
            console.log('API result: ', result);
            if (result && result.data && result.data.data && result.data.data.events) {
                console.log('result.data.data.events: ', JSON.stringify(result.data.data.events))
                // setEvents(result.data.data.events)
                processEvents(result.data.data.events)

                // setEvents([{event_properties: {event_values: {test: '111101101101101101101101101101101101101101101101101101101101101101101101101101101101101101101101100110110110110110110110110110000000011100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000', test2: 'jsdf'}}, event_type: 'test', time_ISO: 'testing123 date here' }])
            }
        }
    }

    const processEvents = (events) => {
        if (events.length===0)  {
            setFinalSeries([])
            let options = returnXAxisValues(1)
            console.log('options is: ', options)
            setOptions(options)
            return
        }
        let oldest = events[events.length - 1]
        console.log('oldest: ', JSON.stringify(oldest))
        console.log("oldest milli: ", oldest.time)
        let now = Date.now() / 1000
        let oldestTime = oldest.time / 1000
        console.log('oldest time: ', oldestTime)
        console.log("now: ", now)
        let minutesSinceOldest = parseInt((now - oldestTime) / 60)
        console.log('minutes since oldest: ', minutesSinceOldest)

        let series = []
        let options = returnXAxisValues(minutesSinceOldest)
        console.log('options is: ', options)
        setOptions(options)

        for (let i = 0; i < events.length; i++) {
            let event = events[i]
            // console.log('found event: ', event.event_type)
            if (returnIndexOfEventType(series, event.event_type) === -1) {
                console.log('Building series array ')
                series.push({ name: event.event_type, data: new Array(minutesSinceOldest).fill(0) })
            }
            let index = returnIndexOfEventType(series, event.event_type)
            let minutesSinceEvent = parseInt((now - event.time / 1000) / 60)
            series[index].data[minutesSinceEvent - 1] += 1
            // console.log('min since event: ', minutesSinceEvent)
        }
        console.log('series is this: ', JSON.stringify(series))
        setFinalSeries(series)
    }

    const returnXAxisValues = (numMinutesAgo) => {
        let result = []
        let minutesAgo = numMinutesAgo
        while (minutesAgo > 0) {
            // if (minutesAgo===numMinutesAgo || minutesAgo===1)   {
            var currentDate = new Date();
            let prevDate = new Date(currentDate.getTime() - minutesAgo * 60000);
            result.push('')
            // result.push(prevDate.toUTCString())
            // } else {
            //     result.push('')
            // }
            minutesAgo -= 1
        }
        console.log('xaxis: ', JSON.stringify(result))
        return returnOptions(result)
    }

    const returnIndexOfEventType = (series, eventType) => {
        let result = -1
        for (let i = 0; i < series.length; i++) {
            if (series[i].name === eventType) return i
        }
        return result
    }

    const returnOptions = (categories) => {
        console.log('categoreis: ', categories)
        let options = {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            // xaxis: {
            //     type: 'datetime',
            //     min: 0,
            //     tickAmount: 6,
            //   },
            //   tooltip: {
            //     x: {
            //       format: 'dd MMM yyyy'
            //     }
            //   },


            xaxis: {
                // type: 'datetime',
                // min: new Date('01 Mar 2012').getTime(),
                // tickAmount: 51,
                // type: 'datetime',
                categories: categories
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        }
        return options
    }






    // render() {
    if (!finalSeries || !options) {
        return (
            <Loading message='Generating Analytics...' />
        )
    }
    return (
        <Container>
            {!!sub && !!sub.name && (
                <Text className={styles.title} text={sub.name + ' Events'} />
            )}
            {!!finalSeries && !!options && (
                <React.Fragment>
                    <div className={styles.chart} id="chart">
                        <ReactApexChart options={options} series={finalSeries} type="area" height={350} />
                    </div>
                    { startTime && (
                    <Text className={styles.timeLabel} text={'Start time: ' + startTime} />

                    )}
                </React.Fragment>
            )}
        </Container>


    );
    // }
}


// import React from 'react'
// import useChartConfig from 'hooks/useChartConfig'
// // import Box from 'components/Box'
// // import SyntaxHighlighter from 'components/SyntaxHighlighter'
// import { Chart } from 'react-charts'
// // let sourceCode
// function Analytics({  }) {
//   const { data, randomizeData } = useChartConfig({
//     series: 10
//   })
//   const series = React.useMemo(
//     () => ({
//       type: 'area'
//     }),
//     []
//   )
//   const axes = React.useMemo(
//     () => [
//       { primary: true, position: 'bottom', type: 'time' },
//       { position: 'left', type: 'linear', stacked: true }
//     ],
//     []
//   )
//   return (
//     <>
//       <button onClick={randomizeData}>Randomize Data</button>
//       <br />
//       <br />
//       {/* <Box> */}
//         <Chart data={data} series={series} axes={axes} tooltip />
//       {/* </Box> */}
//       <br />
//       {/* <SyntaxHighlighter code={sourceCode} /> */}
//     </>
//   )
// }

export default Analytics