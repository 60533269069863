import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import Button from '../../components/Button';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'
// import API from '../../../../API/API.js'
// import { CHAIN_NAME, Web3 } from '../../../../settings'

const article1 = "Getting started with NFTDropper is easy. Just register with your email and we'll be in touch within 24 hours to help with your NFT launch."

function Quickstart({ }) {

    const returnTextChunks = () => {
        let chunks = article1.split('\n')
        return (
            <React.Fragment>
                {chunks.map((text, index) => (
                    <Text className={styles.explanationText} text={text} />
                ))}
            </React.Fragment>
        )
    }

    const getStarted = () => {
        window.location.href = './register'
    }

    const goHome = () => {
        window.location.href = './'
    }

    const returnForm = () => {
        return (
            <React.Fragment>
                {/* <Nav includeSubTitle={true}/> */}
                <Container className={styles.upperContainer}>
                    <Text className={styles.headerText} text={'NFTDropper Quickstart Guide'} />
                    <Button text={'Back'} onItemPress={goHome} className={styles.headerBtn} />
                    <Button text={'Get Started'} onItemPress={getStarted} className={styles.headerBtn} />

                </Container>
                <Text className={styles.titleText} text={'Getting Started'} />

                {/* <Text className={styles.directionsText} text={returnDirections()} /> */}
                {/* <Text className={styles.explanationText} text={article1} /> */}
                {returnTextChunks()}
                <Button text={'Get Started'} primary={true} onItemPress={getStarted} className={styles.btn} />
            </ React.Fragment>
        )
    }

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default Quickstart
