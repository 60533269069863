
import React, { useState, useEffect } from 'react'
import Auth from '../Auth';

function ForgotPassword({ }) {
    return (
        <Auth type={'forgotPassword'} />
    );
}

export default ForgotPassword
