import { css } from 'emotion'
import {colors,fonts} from '../../Styles'

const headerText = css`
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding-top: 14px;
    flex: 1;
`

const explanationText = css`
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 15px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-size: 18px;
    color: #313639;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    letter-spacing: 1px;
`

const upperContainer = css`
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 20px;
    background-color: ${colors.primary};
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
`

const btn = css`
    // border: 1px solid white;
    // color: white;
    width: 132px;
    margin-left: 10%;
    background-color: black;
    border: 1px solid black;
    margin-top: 40px; 
    margin-bottom:40px;
    box-shadow: 5px 10px lightgray;

`

const headerBtn = css`
    border: 1px solid white;
    color: white;
    margin-left: 10px;
    &:hover {
        border: 1px solid white;
        background-color: white;
        color: black;
      }
`

const titleText= css`
      font-size: 25px;
      margin-left: 10%;
      font-weight: 600;
      margin-bottom: 20px;
`

const styles = {
    headerText,
    titleText,
    explanationText,
    btn,
    headerBtn,
    upperContainer,
}

export default styles




