import {colors} from '../../Styles'
import { css } from 'emotion'

const message = css`
  font-weight: 600;
  margin-top: 60px;
`

const mainContainer = css`
  display: flex;
  flex: grow;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const styles = {
  mainContainer,
  message
}

export default styles




