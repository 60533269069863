// import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  background-color: #EAF4FE;
  height: 1000px;
`

const formContainer = css`
  display: flex;
  justify-content: center;
`


const form = css`
    width: 416px;
    margin-top: 80px;
    background-color: white;
    padding: 45px;
    border-radius: 12px;
`

const headerText = css`
  width: 100%;
  text-align: center;
  font-size: 30px;
`

const explanationText = css`
  width: 100%;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 14px;
  color: rgba(17, 17, 17, 0.8);
  font-size: 15px;
  line-height: 18px;
`

const directionsText  = css`
  width: 100%;
  // text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: black;
`

const btn = css`
    margin-top: 20px;
`

const textInput  = css`
    width: 100%;

`

const errorText  = css`
  width: 100%;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 14px;
  color: red;
  font-size: 15px;
  line-height: 18px;

`



const styles = {
  background,
  textInput,
  errorText,
  form,
  formContainer,
  headerText,
  btn,
  explanationText,
  directionsText,

}

export default styles




