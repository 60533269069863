import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../components/Container';
import Button from '../../components/Button';
import Text from '../../components/Text';

function Nav({ scheduleDemo, getStarted, login, showButtons, includeSubTitle }) {

    const home = () => {
        window.location.href = '/'
    }

    const returnLeftSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={styles.clickContainer} onClick={home}>
                    <Text className={styles.titleText} text={'Stream'} />
                     { includeSubTitle && (
                        <Text className={styles.subTitleText} text={'Launch NFT Drops Effortlessly.'} />
                     )}
                </Container>
            </Container>
        )
    }

    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Get Started'} onItemPress={getStarted} primary={true} className={styles.btn} />
        )
    }

    const returnLoginCTA = () => {
        return (
            <Button text={'Sign In'} onItemPress={login} className={styles.secondaryBtn} />
        )
    }

    const quickstart = () => {
        window.location.href='./quickstart'
    }


    const returnScheduleDemoCTA = () => {
        return (
            <Button text={'Schedule Demo'} onItemPress={scheduleDemo} className={styles.secondaryBtn} />
        )
    }

    const returnShowQuickstartCTA = () => {
        return (
            <Button text={'Quickstart'} onItemPress={quickstart} className={styles.secondaryBtn} />
        )
    }

    const returnRightSection = () => {

        return (
            <Container className={styles.section}>
                <Container className={styles.menuItems}>
                    {showButtons && (
                        <React.Fragment>
                            {/* {returnLoginCTA()}
                            {returnScheduleDemoCTA()}
                            {returnShowQuickstartCTA()}
                            {returnTryForFreeCTA()} */}
                        </React.Fragment>
                    )}
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>

            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}

            </Container>

        </Container>
    );
}

export default Nav;
