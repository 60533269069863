import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import Button from '../../components/Button';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'
// import API from '../../../../API/API.js'
// import { CHAIN_NAME, Web3 } from '../../../../settings'

const article0 = "Welcome to the documentation for Blocktimize. In this guide, you will learn how to use the frontend web app and API to integrate and monitor smart contracts seamlessly.\n"
const article1 = "There are several problems and challenges that appear when attempting to integrate smart contracts with servers. These include:\n1. Staying in sync with the state of smart contracts on the blockchain. Setting up event scanning takes a substantial amount of time and detracts from building. The problem increases as more contracts are integrated into the server.\n2. When managing contract metadata on the server side like ABI and other contract metadata, it is hard to maintain a holistic view of all contracts integrated by looking at code. However, having a dashboard allows you to see what contracts are active and how many events they are processing, as well as see the logs of each contract. \n3. Monitoring transactions for reverts or gas issues requires additional, custom code for each contract integrated.\n4. Connecting to analytics engines is difficult and requires formatting events to match each analytics engine API. Blocktimize will automatically format events to match the correct API format for each analytics engine.\nInstead of dealing with complicated parsing and syncing, treat events simply as data coming directly to your server from an external source. The hassle of managing server downtime, event scanning, and contract management and analytics engine connections disappears.\n"
const article2 = 'Once you create an account on Blocktimize, use your account dashboard to add smart contracts that you’d like to use within your servers.'
const article3 = 'For each contract, you will be prompted to provide metadata as well as analytics and webhooks preferences. This allows Blocktimize to create a connection from those smart contracts directly to your servers.'
const article4 = 'Connected smart contracts will show the number of events processed as well as links to log and analytics information.'
const article5 = "When you add a smart contract, optionally specify a URL where you would like webhooks sent via POST requests. Webhooks will begin sending as soon as a contract is created and a webhook URL specified. Event webhooks will be sent as a dictionary payload with the following attributes:\n"
const article6 = "\n Analytics are equally easy to set up. Use analytics to make sure your testnet and mainnet contracts are running smoothly, as well as conduct deeper user segmentation and analysis. Not only does Blocktimize have built-in analysis tools like event charting, it also supports sending events to Amplitude and DataDog. To connect to Amplitude, just specify an amplitude API key where you would like event data sent when adding a contract. Events will immediately begin sending. The web app automatically supports Amplitude, but if you wish to connect to DataDog, just reach out to info@blocktimize.io and we can enable that integration. Sender wallet information and gas information is also added to event data to allow for segmentation and funnel creation within analytics engines. This allows for a much more granular approach to product analysis and allows you to create retention funnels based on your users, giving a much better idea of how your customers are using your decentralized application. Dashboard charts are also exportable to CSV, PNG, or SVG. Here's what analytics looks like in your contract dashboard for a sample contract called 'OpenSea'. Note how the chart shows trends for each event type:"
const article7 = "\nNot only does the web app provide a dashboard for managing all smart contracts and metadata, it also provides event logs in real time for each smart contract. Use these to debug issues and monitor recent transactions. You can view a sample event log below."
const article8 = "\nDaily and weekly reports can also be enabled for each smart contract being monitored. The reports can include summaries of recent smart contract event frequency, information regarding reverts or gas issues, or any other custom data desired."
const article9 = "\nBlocktimize is a no-code platform. Monitoring and analysis can be set up in less than 5 minutes through the web app. Webhooks can start sending to your server in just a few clicks. This makes integrations much easier to set up and manage than maintaining server-side code and metadata. Blocktimize lets you launch, scale and monitor your smart contract business much faster. Create an account and get started in less than 5 minutes."

function Documentation({ }) {

    const returnTextChunks = (num) => {
        let chunks=[]
        if (num===0)    {
            chunks = article0.split('\n')
        }
        if (num===1)    {
            chunks = article1.split('\n')
        }
        if (num===2)    {
            chunks = article2.split('\n')
        }
        if (num===3)    {
            chunks = article3.split('\n')
        }
        if (num===4)    {
            chunks = article4.split('\n')
        }
        if (num===5)    {
            chunks = article5.split('\n')
        }
        if (num===6)    {
            chunks = article6.split('\n')
        }
        if (num===7)    {
            chunks = article7.split('\n')
        }
        if (num===8)    {
            chunks = article8.split('\n')
        }
        if (num===9)    {
            chunks = article9.split('\n')
        }
        article1.split('\n')
        return (
            <React.Fragment>
                {chunks.map((text, index) => (
                    <Text className={styles.explanationText} text={text} />
                ))}
            </React.Fragment>
        )
    }

    const getStarted = () => {
        window.location.href = './register'
    }

    const goHome = () => {
        window.location.href = './'
    }

    const returnCodeSnippet = (code) => {
        let chunks = code.split('\n')
        return (
            <Container className={styles.codeContainer}>
                {chunks.map((text, index) => (
                    <Container className={styles.innerCodeContainer}>
                    <Text className={styles.codeText} text={'• '+text.split('//')[0]} />
                    <Text className={styles.commentText} text={'// '+text.split('//')[1]} />
                    </Container>
                ))}
            </Container>
        )
    }

    const returnForm = () => {
        return (
            <React.Fragment>
                {/* <Nav includeSubTitle={true}/> */}
                <Container className={styles.upperContainer}>
                    <Text className={styles.headerText} text={'Blocktimize'} />
                    <Button text={'Back'} onItemPress={goHome} className={styles.headerBtn} />
                    <Button text={'Get Started'} onItemPress={getStarted} className={styles.headerBtn} />

                </Container>
                <Text className={styles.titleText} text={'Documentation'} />
                <Text className={styles.subTitleText} text={'Background'} />
                {returnTextChunks(0)}
                <Text className={styles.subTitleText} text={'Challenges'} />
                {/* <Text className={styles.directionsText} text={returnDirections()} /> */}
                {/* <Text className={styles.explanationText} text={article1} /> */}
                {returnTextChunks(1)}
                <Text className={styles.subTitleText} text={'Connect Smart Contracts'} />
                {returnTextChunks(2)}

                <Container className={styles.landingImage}>
                </Container>
                {returnTextChunks(3)}
                <Container className={styles.addFormImage}>
                </Container>
                {returnTextChunks(4)}
                <Container className={styles.connectedImage}>
                </Container>
                <Text className={styles.subTitleText} text={'Webhooks'} />
                {returnTextChunks(5)}
                {returnCodeSnippet('event_type // Name of smart contract event (string)\nuser_id // Sender address of the transaction (string)\ntime_ISO // ISO string of event creation time (string)\ntime // Unix timestamp of event creation in milliseconds (integer)\ninsert_id // ID of event log in the EVM (string)\nevent_properties.event_values // Dictionary of event parameter names and values (object)\nevent_properties.contract_name // The name of the contract in the Blocktimize dashboard (string)\nevent_properties.block_number // Block number (number)\nevent_properties.transaction_hash // Transaction hash (string)\nevent_properties.block_hash // Block hash (string)\nevent_properties.transaction_gas_used // Gas used by parent transaction (integer)\nevent_properties.transaction_cumulative_gas_used // Total gas used by cumulative transaction (integer)\nevent_properties.transaction_effective_gas_price // Effective transaction gas price in Gwei (integer)')}
                <Text className={styles.subTitleText} text={'Analytics'} />
                {returnTextChunks(6)}
                <Container className={styles.analyticsImage}>
                </Container>
                <Text className={styles.subTitleText} text={'Logs'} />
                {returnTextChunks(7)}
                <Container className={styles.logImage}>
                </Container>
                <Text className={styles.subTitleText} text={'Reports'} />
                {returnTextChunks(8)}
                <Text className={styles.subTitleText} text={'Final Notes'} />
                {returnTextChunks(9)}
                <Button text={'Get Started'} primary={true} onItemPress={getStarted} className={styles.btn} />
            </ React.Fragment>
        )
    }

//     event_properties.event_values

// contract_name
// :
// "Chainlink"
// subscription_id
// :
// "6ed574bc-0e82-4a01-8e0e-9b6fc2b5aa30"
// category
// :
// "contract_event"
// contract_address
// :
// "0x514910771af9ca656af840dff83e8264ecf986ca"
// block_number
// :
// 12959569
// transaction_hash
// :
// "0xf6894cb4238bdfb8c0becc18ff51c7633e347acb376c3e5f3f22b4d904696d20"
// block_hash
// :
// "0xc2ad9ef0837807a5413d29406876eb3eb32fcda2cee06fe381789f7961b0ede3"
// \nevent_properties.transaction_gas_used // Gas used by parent transaction

// :
// 698683
// \nevent_properties.transaction_gas_used // Gas used by parent transaction\nevent_properties.transaction_cumulative_gas_used // Total gas used by cumulative transaction
// :
// 6159979
// \nevent_properties.transaction_gas_used // Gas used by parent transaction\nevent_properties.transaction_cumulative_gas_used // Total gas used by cumulative transaction\nevent_propertiestransaction_effective_gas_price // Effective transaction gas price in GWei
// :
// 82000000000

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default Documentation
