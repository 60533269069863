import styles from './styles';
import React, { useState, useEffect } from 'react'
import Nav from '../../components/Nav';
import Container from '../../components/Container';
import API from '../../API/API.js'
import Form from '../../components/Form';
import { SUPPORT_EMAIL } from '../../settings';

function Auth({ type }) {

    const [errorMsg,setErrorMsg]=useState(null)

    useEffect(() => {
        checkAuthed()
    },[]);

    const checkAuthed = async () => {
        let token = await localStorage.getItem('jwt')
        if (!!token)    {
            window.location.href='./connect'
        }
    }

    const btnPressed = (payload) => {
        console.log('payload: ', JSON.stringify(payload))
        if (type==='login') {
            login(payload)
        }
        else if (type==='forgotPassword') {
            resetPassword(payload)
        }
        else if (type==='register') {
            register(payload)
        }
    }

    const register = async (payload) => {
        if (!payload) {
            showError('Please provide an email.')
        }
        else if (!payload.email) {
            showError('Missing email.')
        }
        else if (!validateEmail(payload.email)) {
            showError('Invalid email.')
        }
        else{
            showError(null)
            console.log('register pressed')
            let response = await API.register({email: payload.email.trim()})
            console.log('token info: ', JSON.stringify(response))
            if (response.data.token)    {
                let token = response.data.token
                await localStorage.setItem('jwt', token);
                await API.createFundingAccount({})
                window.location.href = '/connect'
            }
        }
    }

    const login = async (payload) => {
        console.log('login has been pressed')
        if (!payload) {
            showError('Please provide your email and password.')
        }
        else if (!payload.email) {
            showError('Missing email.')
        }
        else if (!validateEmail(payload.email)) {
            showError('Invalid email.')
        }
        else if (!payload.password) {
            showError('Missing password.')
        }
        else if (payload.password.length<6) {
            showError('Passwords must be at least 6 characters.')
        }
        else {
            showError(null)
            console.log('logging in...')
            let response = await API.login({email: payload.email.trim(), password: payload.password})
            console.log('response: ', response)
            if (response.data.token)    {
                let token = response.data.token
                await localStorage.setItem('jwt', token);
                window.location.href = '/connect'
            }
            // aler
        }

    }

    const resetPassword = (payload) => {
        console.log('reset password pressed')
        if (!payload) {
            showError('Please provide an email.')
        }
        else if (!payload.email) {
            showError('Missing email.')
        }
        else if (!validateEmail(payload.email)) {
            showError('Invalid email.')
        }
        else{
            showError(null)
            alert('To reset your password, please send an email to ' + SUPPORT_EMAIL)
        }

    }

    const showError = (text) => {
        setErrorMsg(text)
        setTimeout(function(){ setErrorMsg(null) }, 1500);


    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Container className={styles.background}>
            <Nav includeSubTitle={true}/>
            <Container className={styles.formContainer}>
                <Container className={styles.form}>
                    <Form type={type} btnPressed={btnPressed} errorMsg={errorMsg}/>
                </Container>
            </Container>

        </Container>
    );
}

export default Auth
