import React, { useState, useEffect } from 'react'
import Auth from '../Auth';

function Login({ }) {
    return (
        <Auth type={'login'} />
    );
}

export default Login
