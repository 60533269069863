import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const btn = css`
  height: 43px;
  border-radius: 100px;
`

const secondaryBtn = css`
  height: 43px;
  border-radius: 100px;
  margin-right: 15px;
`

const innerPanel = css`
  width: 1200px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const section = css`
  width: 600px;
`

const titleText= css`
  width: 400px;
  letter-spacing: -0.218182px;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.218182px;
  margin-top: 5px;
  color: ${colors.primary};
`

const menuItems = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;

`

const clickContainer= css`
  cursor: pointer;

`

const subTitleText= css`
  color: gray;
  margin-top: 5px;

`


const styles = {
  background,
  btn,
  section,
  clickContainer,
  innerPanel,
  menuItems,
  secondaryBtn,
  subTitleText,
  titleText
}

export default styles




