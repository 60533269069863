import {colors} from '../../../../Styles'
import { css } from 'emotion'
import Image1 from './Images/1.png'
import Image2 from './Images/2.png'
import Image3 from './Images/3.png'
import Swath from './Images/Swath.png'
import BPY from './Images/bapeyacht.webp'
import Doodle from './Images/doodle.gif'
import BMR from './Images/hero.png'
import Punk from './Images/cryptopunk.jpeg'

const background = css`
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const innerPanel = css`
  display: flex;

`

const summaryText = css`
  color: ${colors.primary};
  font-weight: bold;
`

const section = css`
  height: 500px;
  width: 600px;
`

const btn = css`
  width: 160px;
  height: 43px;
  border-radius: 100px;
  margin-right: 10px;
`

const detailText = css`
  width: 477px;
  height: 89px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
`

const heroText = css`
  width: 410px;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  margin-top: 20px;
  margin-bottom: 28px;
  line-height: 89px;
  /* or 139% */
  letter-spacing: -0.04em;
`

const secondImage = css`
  width: 200px;
  height: 200px;
  border-radius: 24px;
  position: absolute;
  margin-top: -85px;
  margin-left: -30px;
  // background-image: url(${Doodle});
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid white;
`

const thirdImage = css`
  width: 240px; // 314px;
  height: 154px; // 253px;
  border-radius: 14px;
  margin-left: -40px;
  margin-top: -468px;
  // background-image: url(${Punk});
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid white;
`

const firstImage = css`
  width: 480px;
  height: 423px;
  border-radius: 24px;
  background-image: url(${BMR});
  background-repeat: no-repeat;
  background-size: cover;
  // transition: visibility 1s linear;
`

const swathImage = css`
  width: 300px;
  height: 13px;
  position: absolute;
  margin-top: -35px;
  border-radius: 24px;
  background-image: url(${Swath});
  background-repeat: no-repeat;
  background-size: cover;
`

const buttonsContainer = css`
  display: flex;
  flex-directions: row;
`

const styles = {
  background,
  btn,
  heroText,
  section,
  swathImage,
  innerPanel,
  buttonsContainer,
  summaryText,
  detailText,
  firstImage,
  secondImage,
  thirdImage,
}

export default styles




