import Container from '../../../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';
import TextField from '@material-ui/core/TextField';
import Nav from '../../../../components/Nav';
import styles from './styles'
import API from '../../../../API/API.js'
// import {  Web3 } from '../../../../settings'


function AddContract({ close }) {

    const [info, setInfo] = useState({destination_service: 'amplitude'})
    const [errorMsg, setErrorMsg] = useState(null)


    useEffect(() => {
    }, []);

    const updateAttribute = (attribute, value) => {

    }

    const handleChange = (event, fieldType) => {
        let text = event.target.value
        let temp = info
        if (fieldType==='abi')  {
            let abi = JSON.stringify(JSON.parse(text))
            if (!!abi)  {
                temp[fieldType] = JSON.stringify(JSON.parse(text))
            }
            console.log('temp: ', JSON.stringify(temp))
        } else {
            temp[fieldType] = text
        }
    }

    const save = async () => {
        // console.log(JSON.stringify(info))
        // if (!info.name) {
        //     setErrorMsg('Missing name.')
        //     return
        // }
        // if (!info.subscribe_to) {
        //     setErrorMsg('Missing contract address.')
        //     return
        // }
        // if (!(Web3.utils.isAddress(info.subscribe_to))) {
        //     setErrorMsg('Invalid contract address.')
        //     return
        // }
        // if (!info.abi) {
        //     setErrorMsg('ABI is required.')
        //     return
        // }
        // if (!info.destination_api_key && !info.webhook_url) {
        //     setErrorMsg('Provide an Amplitude key or webhook url for connection.')
        //     return
        // }
        // console.log('info:', JSON.stringify(info))
        // let response = await API.subscribe(info)
        // console.log('response: ', response)
        // close()
    }

    const returnTextField = (placeholder, fieldType) => {
        return (
            <TextField
            placeholder={placeholder}
            // id={index.toString()}
            // label={field.name}
            // defaultValue={this.props.defaultToDoValue}
            className={styles.textInput}
            // multiline
            // margin="normal"
            // type={false}
            variant="outlined"
            onChange={ (e) => handleChange(e,fieldType)}
        />
        )
    }

    const returnForm = () => {
        return (
            <React.Fragment>
                <Text className={styles.headerText} text={'Add TODO METADATA'} />
                {/* <Text className={styles.directionsText} text={returnDirections()} /> */}
                <Text className={styles.explanationText} text={'Choose Contract Name'} />
                {returnTextField('Example: NFTSwap Exchange', 'name')}
                <Text className={styles.explanationText} text={'Ethereum Contract Address'} />
                {returnTextField('0xabc...', 'subscribe_to')}
                <Text className={styles.explanationText} text={'Contract ABI Array'} />
                {returnTextField('[...]', 'abi')}
                <Text className={styles.explanationText} text={'Webhook Url (optional)'} />
                {returnTextField('https://www.example.com/events', 'webhook_url')}
                <Text className={styles.explanationText} text={'Amplitude API Key (optional)'} />
                {returnTextField('XXXXXXXXXXXXXXX', 'destination_api_key')}
                <Text className={styles.errorText} text={errorMsg} />
                <Button text={'Save'} onItemPress={save} primary={true} className={styles.btn} />
                <Button text={'Cancel'} onItemPress={close} className={styles.btn} />

            </ React.Fragment>
        )
    }

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default AddContract
