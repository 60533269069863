// Env dependent settings and constants are contained in this file.

const env = process.env.REACT_APP_NODE_ENV ? process.env.REACT_APP_NODE_ENV : 'staging'
console.log('Web app environment: ', env)

// Initializations:
let SERVER_BASE = ''
// Assignments:
const DOMAIN = 'getstreamapp.com'
const VERSION_NUMBER = '1.0'
const PRICES_RESOLUTION = 1000
const SUPPORT_EMAIL = 'support@' + DOMAIN
if (env === 'staging' || env === 'development') {
    SERVER_BASE = 'https://stream-rest-staging.herokuapp.com'
} else if (env === 'production') {
    SERVER_BASE = 'https://stream-rest-production.herokuapp.com'
}
const MAIL_TO_SUPPORT_URL = 'mailto:' + SUPPORT_EMAIL + '?subject=Support%20Request'
const APP_URL = 'https://apps.apple.com/us/app/stream-get-paid-early/id6446709359' // 'mailto:' + SUPPORT_EMAIL + '?subject=Join%20Waitlist' // 'https://apps.apple.com/us/app/....'

export { MAIL_TO_SUPPORT_URL, SUPPORT_EMAIL, PRICES_RESOLUTION, APP_URL, DOMAIN, VERSION_NUMBER, SERVER_BASE }