import { css } from 'emotion'
import { isMobile } from "react-device-detect";
import {colors,fonts} from '../../Styles'
import Analytics from './assets/analytics.png'
import Log from './assets/event.png'
import AddForm from './assets/addForm.png'
import Connected from './assets/connected.png'
import Event from './assets/event.png'
import Landing from './assets/landing.png'

const headerText = css`
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding-top: 14px;
    flex: 1;
`

const explanationText = css`
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 15px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-size: 18px;
    color: #313639;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    letter-spacing: 1px;

    font-family: Avenir;
`

const upperContainer = css`
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 20px;
    background-color: ${colors.primary}; //#2991F0;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
`

const btn = css`
    // border: 1px solid white;
    // color: white;
    width: 132px;
    margin-left: 10%;
    background-color: black;
    border: 1px solid black;
    margin-top: 40px; 
    margin-bottom:40px;

    box-shadow: 5px 10px lightgray;
`

const analyticsImage = css`
    // height: 400px;
    // 
    // width: ${isMobile ? '250px;' : null}
    height: ${isMobile ? '95px;' : '400px;'}
    // margin: auto;

    margin-right: ${isMobile ? 'auto;' : '10%;'}
    margin-left: ${isMobile ? 'auto;' : '10%;'}


    // margin-right: 10%;
  background-image: url(${Analytics});
  background-repeat: no-repeat;
  background-size: cover;

  margin-top: 40px;
  margin-bottom: 40px;
`

const logImage = css`
  width: ${isMobile ? '250px;' : '930px;'}
  height: ${isMobile ? '95px;' : '350px;'}
  margin: auto;
  background-image: url(${Log});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 40px;
`

const addFormImage = css`
  width: ${isMobile ? '250px;' : '930px;'}
  height: ${isMobile ? '245px;' : '850px;'}
  margin: auto;
  background-image: url(${AddForm});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 40px;
`

const connectedImage = css`
  width: ${isMobile ? '250px;' : '930px;'}
  height: ${isMobile ? '235px;' : '790px;'}
  margin: auto;
  background-image: url(${Connected});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 40px;
`

const eventImage = css`
  width: ${isMobile ? '250px;' : '930px;'}
  height: ${isMobile ? '95px;' : '350px;'}
  margin: auto;
  background-image: url(${Event});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 40px;
`

const landingImage = css`
  width: ${isMobile ? '250px;' : '630px;'}
  height: ${isMobile ? '175px;' : '440px;'}
  margin: auto;
  background-image: url(${Landing});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 40px;
`

const headerBtn = css`
    border: 1px solid white;
    color: white;
    margin-left: 10px;
    &:hover {
        border: 1px solid white;
        background-color: white;
        color: black;
      }
`

const titleText= css`
      font-size: 25px;
      margin-left: 10%;
      font-weight: 600;
      margin-bottom: 20px;
`

const subTitleText= css`
font-size: 20px;
margin-left: 10%;
font-weight: 600;
// color: #353839;
margin-bottom: 20px;
margin-top: 30px;
color: ${colors.primary};

// text-decoration-line: underline;
// background-color: lightgray;
// padding: 10px;
// border-radius: 5px;
// width: 150px;
`

const codeContainer = css`
    margin-left: 10%;
    margin-right: 10%;
    padding: 20px;
    border: 2px solid #705BC5;
    // background-color: lightgray;
    border-radius: 5px;
    margin-top: 40px;
    margin-bottom: 40px;

    box-shadow: 5px 10px lightgray;

`

const codeText = css`
color: #705BC5;
font-weight: 600;
    // color: #696969;
    font-family: Avenir;
    // font-size: 10px;
    font-size: ${isMobile ? '10px;' : '18px;'}
`

const commentText  = css`
color: #696969;
      margin-left: 10px;
      font-size: 18px;
    //   font-size: ${isMobile ? '10px;' : '18px;'}
      display: ${isMobile ? 'none' : null}
      
`

const innerCodeContainer  = css`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`

const styles = {
    headerText,
    titleText,
    explanationText,
    btn,
    headerBtn,
    codeContainer,
    innerCodeContainer,
    logImage,
    codeText,
    subTitleText,
    landingImage,
    upperContainer,
    connectedImage,
    eventImage,
    addFormImage,
    commentText,
    analyticsImage,
}

export default styles




