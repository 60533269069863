
import React, { useState, useEffect } from 'react'
import Auth from '../Auth';

function Redirect({ }) {

    useEffect(() => {
        window.open('https://testflight.apple.com/join/RLdkubND', '_blank')
    }, []);

    return null
}

export default Redirect
