
import React, { useState, useEffect } from 'react'
import Auth from '../Auth';

function Register({ }) {
    return (
        <Auth type={'register'} />
    );
}

export default Register
