// import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  background-color: #EAF4FE;
  height: 1000px;
`

const form = css`
    width: 416px;
    margin-top: 80px;
`

const formContainer = css`
  display: flex;
  justify-content: center;
`


const styles = {
  background,
  form,
  formContainer

}

export default styles




