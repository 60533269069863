import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from '@reach/router'
import App from './App';
import reportWebVitals from './reportWebVitals';
import Connect from './Screens/Connect';
import Login from './Screens/Login';
import Analytics from './Screens/Analytics';
import Quickstart from './Screens/Quickstart';
import Documentation from './Screens/Documentation'
// import Blog from './Screens/Blog';
import Register from './Screens/Register';
import ForgotPassword from './Screens/ForgotPassword';
import Logs from './Screens/Logs';
import Support from './Screens/Support';
import ResetPassword from './Screens/ResetPassword';
import Terms from './Screens/Terms';
import OAuthLink from './Screens/OAuthLink';
import Privacy from './Screens/Privacy';
import Redirect from './Screens/Redirect';
import ReturnPage from './Screens/ReturnPage';

ReactDOM.render(
  <Router>
    <App default />
    <ReturnPage path="/verify/return" />
    <ResetPassword path="/reset" />
    <OAuthLink path="/link" />
    <Support path="/support" />
    <Terms path="/legal/terms" />
    <Privacy path="/legal/privacy" />
    <Redirect path="/redirect" />
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
