import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  // background-color: #EAF4FE;
  height: 1000px;
  padding: 40px;
`

const form = css`
    width: 416px;
    margin-top: 80px;
`

const formContainer = css`
  display: flex;
  justify-content: center;
`

const eventContainer = css`
  margin-bottom: 50px;
  // background-color: #EAF4FE;
  background-color: #705BC5;
  border: 1px solid #705BC5;
  padding: 20px;
  border-radius: 5px;
  margin-right: 10%;
  box-shadow: 5px 10px lightgray;
`

const eventTitle =  css`
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: 600;
  color: white;
`

const title = css`
width: 100%;
text-align: center;
  margin-bottom: 60px;
  margin-top: 50px;
  font-weight: 600;
  font-size: 25px;
`

const date = css`
  margin-top: 15px;
  // color: grey;
  color: white;
`

const valuesContainer = css`
  padding: 8px;
  background-color: white;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid #705BC5;
`

const key  = css`
  // font-weight: 600;
  font-size: 16px;
  margin-top: 5px;
  color: #705BC5;
  font-weight: 600;
`

const val = css`
  // margin-top: 10px;
  margin-bottom: 5px;
  word-break: break-all;
  white-space: normal;
  color: grey;
`

const chart = css`
  margin-left: 5%;
  margin-right: 5%;
  // height: 300px;
`

const timeLabel = css`
margin-top: 30px;
width: 100%;
text-align: center;
color: gray;
`



const styles = {
  background,
  date,
  val,
  timeLabel,
  valuesContainer,
  eventContainer,
  eventTitle,
  chart,
  key,
  form,
  formContainer,
  title

}

export default styles




